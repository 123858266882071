<template>
  <div class="flex flex-col gap-4">
    <h1 class="text-xl font-weight-bold">
      <div v-if="template === null">Select template</div>
      <div v-else>Template {{ template.text }}</div>
    </h1>

    <div
      v-if="template === null"
      class="flex flex-col gap-4"
      style="max-width: 150px;"
    >
      <b-btn
        variant="white"
        size="sm"
        v-for="template in templates"
        :key="template.value"
        @click="selectTemplate(template)"
      >
        {{ template.text }}
      </b-btn>
    </div>
    <div
      v-else-if="candle"
      class="flex flex-col gap-4"
    >
      <div>
        <b-btn
          variant="primary"
          size="sm"
          @click="selectTemplate(null)"
        >
          Go back
        </b-btn>
      </div>

      <h2
        v-if="busy.load"
        class="text-xl font-weight-bold"
      >
        Loading..
      </h2>

      <b-card no-body>
        <b-tabs pills card>
          <b-tab title="Layers" active>
            <b-card-text>
              <div class="flex flex-col gap-4">
                <template-layer
                  v-for="layer in candle.layers"
                  :key="layer"
                  :template="template"
                  :layer="layer"
                />
              </div>
            </b-card-text>
          </b-tab>
          <b-tab title="Config">
            <b-card-text>
              <code><pre>{{ candle.config }}</pre></code>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
const TemplateLayer = () => import('@/components/templates/TemplateLayer.vue');

export default {
  name: 'CandleTemplates',
  components: {
    TemplateLayer,
  },
  data() {
    return {
      candle: null,
      template: null,
      templates: null,
      busy: {
        load: 0,
      },
    };
  },
  methods: {
    fetchTemplates() {
      this.busy.load++;
      this.$http
        .get('/template')
        .then((res) => {
          this.templates = res.body.templates;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch templates: ${err.response.text}`);
        })
        .finally(() => {
          this.busy.load--;
        });
    },
    fetchCandle() {
      this.candle = null;
      this.busy.load++;
      this.$http
        .get('/layer')
        .query({ template: this.template.value })
        .then((res) => {
          this.candle = res.body;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch candle: ${err.response.text}`);
        })
        .finally(() => {
          this.busy.load--;
        });
    },
    selectTemplate(template) {
      this.template = template;
      if (template) {
        this.fetchCandle();
      }
    },
  },
  created() {
    this.fetchTemplates();
  },
};
</script>

<style lang="scss" scoped>
</style>
